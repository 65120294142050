<template>
  <div class="page-content vehicle-info">
    <div class="page-form content">
      <Form
        :ref="`form-work`"
        :defaultForm.sync="form"
        :fields="fields"
        :defaultText.sync="fieldTexts"
      >
        <van-field
          slot="field-custom-vin"
          v-model.trim="form.vin"
          label="车架号"
          disabled
          input-align="right"
        >
          <template #button>
            <div
              :class="['field-btn', assessIng ? 'ing' : '']"
              @click="assessFn"
            >
              {{ assessIng ? "评估中 " : "发起评估" }}
            </div>
          </template>
        </van-field>
      </Form>
    </div>

    <div class="page-btn btns">
      <van-button type="info" size="small" @click="submitFn">提交</van-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { assessVehicle, createVehicleInfo } from "@/api/apply";
import { setFormData } from "../dev_form_json";
export default {
  components: {
    Form: () => import("@/components/Form/Index")
  },
  data() {
    return {
      required: [
        {
          required: true
        }
      ],
      bizNo: "",
      assessIng: false,
      assessSuccess: false,
      form: {},
      fieldTexts: {}
    };
  },
  computed: {
    fields() {
      return [
        {
          type: "text",
          label: "车牌",
          disabled: true,
          key: "vehiclePlateNo"
        },
        {
          type: "custom",
          label: "车架号",
          key: "vin"
        },
        {
          type: "text",
          label: "车辆颜色",
          required: true,
          key: "carColor"
        },
        {
          type: "region",
          label: "车辆上牌地",
          required: true,
          namesKey: ["licenseProvinceName", "licenseCityName"],
          keys: ["licenseProvinceCode", "licenseCityCode"],
          key: "licenseCode",
          change: ({ codes }) => {
            this.$set(this.form, "licenseCode", codes);
          }
        },
        {
          type: "text",
          label: "购买金额(元)",
          required: true,
          key: "purchaseAmount"
        },
        {
          type: "text",
          label: "受理地",
          required: true,
          key: "acceptLocation"
        },
        {
          type: "number",
          label: "一年内过户次数",
          labelWidth: "7.2em",
          required: true,
          key: "transferNumber"
        },
        {
          type: "text",
          label: "车辆准载限制",
          required: true,
          key: "loadLimit"
        },
        {
          type: "select",
          label: "车辆是否解押",
          required: true,
          key: "releasePledge",

          enums: [
            {
              text: "是",
              value: "Y"
            },
            {
              text: "否",
              value: "N"
            }
          ]
        }
      ];
    }
  },
  mounted() {
    this.init();
    window.agilityAutoFill = this.agilityAutoFill;
  },
  methods: {
    ...mapGetters(["getUserOrderInfo"]),
    init() {
      const data = this.getUserOrderInfo();
      this.bizNo = data.bizNo || "";
    },
    async assessFn() {
      if (this.assessIng) {
        return;
      }
      try {
        this.assessIng = true;
        await assessVehicle(this.bizNo);
        this.assessSuccess = true;
      } catch (error) {
        this.assessSuccess = false;
        this.$errMsg(error);
      } finally {
        this.assessIng = false;
      }
    },
    async submitFn() {
      try {
        const infodom = this.$refs["form-work"];
        await infodom.validate();
        const params = {
          bizNo: this.bizNo,
          ...this.form
        };
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true
        });
        await createVehicleInfo(params);
        this.$toast.clear();
        this.$router.push("/apply-detail");
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 开发快速调试
    agilityAutoFill() {
      let isdev = process.env.NODE_ENV === "development";
      if (isdev) {
        setFormData(this, "form", "apply_form_data_car_form");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./input-style.scss";
.vehicle-info {
  .field-btn.ing {
    color: #666;
  }
  .btns {
    .van-button {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
